import {Component, Input, DoCheck, KeyValueDiffers, OnInit, KeyValueDiffer} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {NGXLogger} from 'ngx-logger';
import {States} from './states';
import {StopStatus} from './stop_status';

declare let ALKMaps: any;

@Component({
  selector: 'app-map-drawer',
  templateUrl: './map_drawer.html',
  styleUrls: ['./map_drawer.scss']
})
export class MapDrawerComponent implements DoCheck, OnInit {
  @Input() displayedUnits: any;
  @Input() unitMap: object;
  @Input() drawerOpen: boolean;
  @Input() filterCallback: Function;
  @Input() unitClicked: Function;
  @Input() gridHeight: number;
  @Input() plotRoute: Function;
  @Input() removeRoute: Function;
  @Input() clearMarkers: Function;
  @Input() addMarkers: Function;
  filterValue = '';
  slide = false;
  optionsExpanded = false;
  isTollRoads = false;
  isHazmat = false;
  isOpenBorders = false;
  dragging = false;
  currentStop: any;
  iterableDiffer: {};
  route: any;
  routeLoading = false;
  differ: KeyValueDiffer<string, any>;
  stops = [
    new Object({location: '', id: Math.random() + ''}),
    new Object({location: '', id: Math.random() + ''})
  ];
  currentLocationQuery = '';

  searchResults = [];

  constructor(private logger: NGXLogger,
      private iterableDiffers: KeyValueDiffers) {
    this.differ = iterableDiffers.find([]).create();
  }

  ngOnInit() {
    this.iterableDiffer = {};
    this.stops.forEach((element) => {
      this.iterableDiffer[element['id']] = this.iterableDiffers.find(element).create();
    });
  }

  ngDoCheck() {
    const changes = this.differ.diff(this.stops);

    if (changes) {
      changes.forEachAddedItem((record) => {
        if (record.currentValue['result']) {
          this.plotRouteOnMap();
        }
      });
      changes.forEachRemovedItem((record) => {
        this.plotRouteOnMap();
      });
    }

    this.stops.forEach(stopElement => {
      const objDiffer = this.iterableDiffer[stopElement['id']];
      const objChanges = objDiffer.diff(stopElement);
      if (objChanges) {
        objChanges.forEachChangedItem((element) => {
          if (element.key === 'location' && stopElement['result']) {
            this.plotRouteOnMap();
          }
        });
      }
    });
  }

  plotRouteOnMap() {
    const latLongs = [];
    this.stops.forEach(stop => {
      if (stop['result'] && stop['result']['Coords']) {
        const coords = stop['result']['Coords'];
        latLongs.push(new ALKMaps.LonLat(coords.Lon, coords.Lat));
      }
    });
    if (latLongs.length >= 2) {
      this.removeRoute();
      const options = {
        zoomToBounds: true,
        bordersOpen: this.isOpenBorders,
        tollDiscourage: this.isTollRoads,
        hazMatType: this.isHazmat
      };
      this.routeLoading = true;
      const callback = result => {
        this.route = result;
        this.routeLoading = false;
      };
      this.plotRoute(latLongs, callback, options);
    }
  }

  addStop() {
    const newStop = new Object({location: '', id: Math.random()});
    this.iterableDiffer[newStop['id']] = this.iterableDiffers.find(newStop).create();
    this.stops.push(newStop);
  }

  removeStop(stop: any, index: number) {
    this.stops.splice(index, 1);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.stops, event.previousIndex, event.currentIndex);
  }

  searchLocation(location: string, stop = {}) {
    stop['result'] = undefined;
    if (location === '') {
      this.searchResults = [];
      return;
    }
    this.currentLocationQuery = location;
    ALKMaps.Geocoder.singleSearch({
      query: location,
      maxResults: 5,
      countries: `US${this.isOpenBorders ? ',CA' : ''}`,
      region: 'NA',
      success: results => {
        if (location === this.currentLocationQuery) {
          stop['result'] = undefined;
          this.currentStop = stop;
          this.searchResults = [];
          if (results) {
            results.Locations.forEach(result => {
              let viewString = '';
              const address = result.Address;
              if (result.ResultType === 9) {
                viewString += result.ShortString;
                viewString += address.StreetAddress ? ` ${address.StreetAddress},` : ' ';
                viewString += address.City + ', ';
                viewString += address.State;
              } else {
                viewString += address.StreetAddress ? `${address.StreetAddress} ` : '';
                viewString += address.City + ', ';
                viewString += address.State;
              }
              this.searchResults.push({
                viewString: this.highlight(viewString, location),
                nonAttributeString: viewString,
                result: result
              });
            });
          }
        }
      },
      failure: error => {
        this.logger.info(error);
      }
    });
  }

  setLocation(location, stop) {
    this.currentLocationQuery = '';
    stop.location = location.nonAttributeString;
    stop.result = location.result;
    this.searchResults = [];
    this.plotRouteOnMap();
  }

  highlight(value, key): string {
    if (!value || !key) {
      return value;
    }
    return value.replace(new RegExp(key, 'gi'), match => {
      return '<strong>' + match + '</strong>';
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();

    if (this.unitMap && Object.keys(this.unitMap).length > 0) {

      const filteredUnits = Object.keys(this.unitMap).filter(unitKey => {
        const unit = this.unitMap[unitKey];
        const delivery = unit.delivery_stop || {};
        const nextStop = unit.nextStop || {};
        const pickup = unit.pickup_stop || {};
        const unitResult = Object.keys(unit).some(key => {
          return (unit[key] + '').toLowerCase().includes(filterValue);
        });
        const nextStopResult = Object.keys(nextStop).some(key => {
          return (nextStop[key] + '').toLowerCase().includes(filterValue);
        });
        const pickupResult = Object.keys(pickup).some(key => {
          return (pickup[key] + '').toLowerCase().includes(filterValue);
        });
        const deliveryResult = Object.keys(delivery).some(key => {
          return (delivery[key] + '').toLowerCase().includes(filterValue);
        });

        const states = Object.keys(States).filter(key => {
          return key.toLowerCase().includes(filterValue);
        });

        const stateResult = states.some(state => {
          state = States[state];
          return (delivery.location_state + '').toLowerCase() === state ||
              (nextStop.location_state + '').toLowerCase() === state ||
              (pickup.location_state + '').toLowerCase() === state;
        });

        const statuses = Object.keys(StopStatus).filter(key => {
          return key.toLowerCase().includes(filterValue);
        });

        const statusResult = statuses.some(status => {
          status = StopStatus[status];
          return nextStop.status === status || (!unit.nextStop && ('completed'.includes(filterValue) || 'available'.includes(filterValue)));
        });

        const result = unitResult || pickupResult || deliveryResult || nextStopResult || stateResult || statusResult;
        if (unit.marker) {
          this.filterCallback(unit, result);
        }
        return result;
      });
      this.displayedUnits = [];
      filteredUnits.forEach(unit => {
        this.displayedUnits.push(this.unitMap[unit].observable);
      });
    }
  }
}
