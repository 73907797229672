import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-slide-panel',
  templateUrl: './slide_panel.html',
  styleUrls: ['./slide_panel.scss'],
  animations: [
    trigger('slide', [
      state('left', style({transform: 'translateX(0)'})),
      state('right', style({transform: 'translateX(-50%)'})),
      transition('* => *', animate(200))
    ])],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlidePanelComponent {
  @Input() activePane: PaneType = 'left';
}

type PaneType = 'left' | 'right';
