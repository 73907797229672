import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {DOCUMENT, Location} from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireAnalytics} from '@angular/fire/analytics';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {MapService} from './map.service';
import {ScriptService} from '../scripts/script.service';
import {environment} from '../../environments/environment';

declare let ALKMaps: any;

@Component({
  templateUrl: './map.html',
  styleUrls: ['./map.scss']
})
export class MapComponent implements OnInit, OnDestroy {

  gridHeight: number = window.innerHeight - 90;
  map: any;
  lon = -96;
  lat = 37;
  zoom = 5;
  mapOptions = {
    wrapDateLine: true,
    displayInLayerSwitcher: true
  };
  markerLayer: any;
  incidentsLayer: any;
  weatherLayer: any;
  flowLayer: any;
  routingLayer: any;
  incidentEvents: any;
  drawerOpen = false;
  drawerWidth = 23;
  drawerContentWidth = 0;
  defaultMap: any;
  satelliteMap: any;
  terrainMap: any;
  weatherLayerActive = false;
  incidentsLayerActive = false;
  flowLayerActive = false;
  markerLayerActive = true;
  currentBaseMap = 'default';
  customerId: string;
  unitMap = {};
  markerMap = {};
  displayedUnits: Observable<{}>[] = [];
  previousUnit: any;
  mapLoading = true;
  isLoading = true;
  unitSelected = false;
  unitsObservable: Observable<Observable<{}>[]>;

  constructor(
      private logger: NGXLogger,
      @Inject(DOCUMENT) public document: Document,
      private scriptService: ScriptService,
      private activatedRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private service: MapService,
      private location: Location,
      @Inject('firebaseAnalytics') private firebaseAnalytics: AngularFireAnalytics,
      @Inject('firebaseDatabaseAuth') private firebaseAuth: AngularFireAuth) {
  }

  ngOnInit() {
    this.scriptService.load('ALKMaps')
        .then(() => {
          this.setupMap();
          this.addBaseLayers();
          const userbaseMap = localStorage.getItem('baseMap');
          if (userbaseMap !== 'null') {
            this.setBaseLayer(userbaseMap);
          }

          this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.customerId = params['customerId'] || localStorage.getItem('customerId');
            localStorage.setItem('customerId', this.customerId);
            this.firebaseAnalytics.logEvent('page_view', { page: 'ptMaps', label: this.customerId });
            this.location.replaceState('map');
            this.setupDataSource();
            this.loadIconsAfterMap();
            this.setupFeatureLayers();
          });
        });
    this.resizeEvent();
    window.addEventListener('resize', this.resizeEvent.bind(this), {passive: true});
  }

  setupDataSource() {
    this.service.map = this.map;
    this.firebaseAuth.signInAnonymously()
        .then(result => {
          this.unitsObservable = this.service.subsribeToFirestore(this, this.customerId, this.markerClickHandler);
          this.unitsObservable.subscribe(value => {
            value.forEach(obs => {
              this.displayedUnits.push(obs);
              obs.subscribe(unit => {
                const marker = unit['marker'];
                if (marker) {
                  if (this.markerMap && marker.unit.truck_no && this.markerMap[marker.unit.truck_no]) {
                    this.markerLayer.removeMarker(this.markerMap[marker.unit.truck_no]);
                  }
                  const oldMarker = this.markerMap[unit['truck_no']];
                  this.markerMap[unit['truck_no']] = marker;
                  if (!this.mapLoading && !this.unitSelected || (this.unitSelected && unit['selected'])) {
                    this.markerLayer.addMarker(marker);
                    if (oldMarker) {
                      marker.setVisibility(oldMarker.visible());
                    }
                  }
                }
                unit['observable'] = obs;
                this.unitMap[unit['truck_no']] = unit;
              });
            });
          });
        })
        .catch(error => {
          this.logger.error(error);
        });
  }

  loadIconsAfterMap() {
    this.map.baseLayer.events.on({
      'loadend': () => {
        Object.keys(this.markerMap).forEach(key => {
          this.markerLayer.addMarker(this.markerMap[key]);
        });
        this.mapLoading = false;
        this.isLoading = false;
        this.map.baseLayer.events.listeners.loadend = [];
      }
    });
  }

  markerClickHandler(unit: any, marker, context = this) {
    if (!context.drawerOpen) {
      context.openChanged();
    }
    context.markerLayer.markers.forEach(m => {
      let newImage = './assets/marker_blue.svg';
      if (m.unit.isLate) {
        newImage = './assets/marker_red.svg';
      } else if (m.unit.isOnTime) {
        newImage = './assets/marker_green.svg';
      }
      m.setContentHTML(`<img style="width: 31px; height: 42px" src="${newImage}" />`);
    });
    marker.setContentHTML(`<img style="width: 31px; height: 42px" src="../../assets/marker_grey.svg" />`);
    context.unitClicked(unit, false);
    const el = document.getElementById(unit.truck_no);
    el.scrollIntoView({behavior: 'auto', block: 'center'});
  }

  setMarkerVisibility(unit, value) {
    this.markerMap[unit.truck_no].setVisibility(value);
  }

  setupMap() {
    ALKMaps.APIKey = environment.pcMilerAPIKey;
    this.map = new ALKMaps.Map('alkMap');
    this.map.removeControl(this.map.controls[1]);
    this.defaultMapLocation();
  }

  defaultMapLocation() {
    const lonLat = new ALKMaps.LonLat(this.lon, this.lat)
        .transform(new ALKMaps.Projection('EPSG:4326'), this.map.getProjectionObject());
    this.map.setCenter(lonLat, this.zoom);
  }

  addBaseLayers() {
    this.defaultMap = new ALKMaps.Layer.BaseMap('Default', {style: ALKMaps.STYLE.DEFAULT}, this.mapOptions);
    this.satelliteMap = new ALKMaps.Layer.BaseMap('Satellite', {style: ALKMaps.STYLE.SATELLITE}, this.mapOptions);
    this.terrainMap = new ALKMaps.Layer.BaseMap('Terrain', {style: ALKMaps.STYLE.TERRAIN}, this.mapOptions);
    this.map.addLayers([this.defaultMap, this.satelliteMap, this.terrainMap]);
  }

  setupFeatureLayers() {
    const self = this;
    const onError = function(error) {
      self.logger.error(error);
    };


    this.weatherLayer = new ALKMaps.Layer.WeatherRadar('Weather', {onErrorCallback: onError});

    this.incidentsLayer = new ALKMaps.Layer.TrafficIncidents('Traffic Incidents', {onErrorCallback: onError});

    this.incidentEvents = new ALKMaps.Control.FeatureEvent([this.incidentsLayer],
        {geometryTypes: ['ALKMaps.Geometry.Point']});

    this.incidentsLayer.events.register('overFeature', this.incidentsLayer, event => {
      return this.incidentsLayer.onmouseover(event.feature);
    });

    this.incidentsLayer.events.register('featureclicked', this.incidentsLayer, event => {
      return this.incidentsLayer.onmouseclick(event.feature);
    });

    this.flowLayer = new ALKMaps.Layer.Traffic('LiveTraffic™', {region: 'NA'}, {onErrorCallback: onError});

    this.routingLayer = new ALKMaps.Layer.Routing('Routing', {
      displayInLayerSwitcher: false,
      waypointURL: ALKMaps.IMAGE.FLAG_STOP,
    });

    this.map.addLayer(this.routingLayer);

    this.markerLayer = new ALKMaps.Layer.Markers('Markers');
    this.map.addLayer(this.markerLayer);
    this.setMapZIndex();
  }

  // TODO: (Brian) Move to Service Layer
  geocodeStops(stops: Array<any>): Promise<any> {
    const promises = [];
    stops.forEach(stop => {
      promises.push(new Promise((resolve, reject) => {
        ALKMaps.Geocoder.geocode({
          address: {
            addr: stop['location_street1'],
            city: stop['location_city'],
            state: stop['location_state'],
            zip: stop['location_zip']
          },
          listSize: 1,
          success: function(response) {
            resolve(response);
          },
          failure: function(response) {
            reject(response);
          }
        });
      }));
    });
    return Promise.all(promises);
  }

  plotRoute(stops: Array<any>, callback: Function,
      options: {} = {zoomToBounds: false, bordersOpen: false, tollDiscourage: false, hazMatType: false}) {
    stops =
        ALKMaps.LonLat.transformArray(stops, new ALKMaps.Projection('EPSG:4326'), this.map.getProjectionObject());
    this.routingLayer.addRoute({
      stops: stops,
      functionOptions: {
        routeId: 'ptRoute',
        frameRoute: false,
        style: {},
        success: results => {
          if (options['zoomToBounds'] === true) {
            const bounds = new ALKMaps.Bounds();
            results.stops.forEach(stop => {
              bounds.extend(stop);
            });
            const ratio = 366 / window.innerHeight;
            const lonLat = new ALKMaps.LonLat(bounds.left - ((bounds.right - bounds.left) * ratio),
                (bounds.top + bounds.bottom) / 2);
            bounds.extend(lonLat);
            this.map.zoomToExtent(bounds);
          }
          if (callback) {
            callback(results);
          }
        }
      },
      routeOptions: {
        bordersOpen: options['bordersOpen'],
        tollDiscourage: options['tollDiscourage'],
        hazMatType: options['hazMatType'] ? 1 : 0,
        useTraffic: true
      },
      reportOptions: {type: 'Mileage', format: 'json', condenseDirs: true}
    });
  }

  removeRoute() {
    this.routingLayer.removeRoute('ptRoute');
  }

  // TODO: (Brian) Move to Service Layer
  createRoute(stops: Array<any>, callback: Function) {
    this.geocodeStops(stops)
        .then(locations => {
          stops = [];
          locations.forEach(location => {
            stops.push(new ALKMaps.LonLat(location[0].Coords.Lon, location[0].Coords.Lat));
          });
          this.plotRoute(stops, callback);
        })
        .catch(error => {
          this.logger.log(error);
        });
  }

  toggleWeatherLayer() {
    if (this.weatherLayerActive) {
      this.map.removeLayer(this.weatherLayer);
    } else {
      this.map.addLayer(this.weatherLayer);
    }
    this.weatherLayerActive = !this.weatherLayerActive;
  }

  toggleIncidentsLayer() {
    if (this.incidentsLayerActive) {
      this.map.removeLayer(this.incidentsLayer);
      this.incidentEvents.deactivate();
      this.map.removeControl(this.incidentEvents);
    } else {
      this.map.addLayer(this.incidentsLayer);
      this.map.addControl(this.incidentEvents);
      this.incidentEvents.activate();
    }
    this.incidentsLayerActive = !this.incidentsLayerActive;
  }

  toggleFlowLayer() {
    if (this.flowLayerActive) {
      this.map.removeLayer(this.flowLayer);
    } else {
      this.map.addLayer(this.flowLayer);
    }
    this.flowLayerActive = !this.flowLayerActive;
  }

  toggleMarkerLayer() {
    this.markerLayerActive = !this.markerLayerActive;
    this.markerLayer.setOpacity(this.markerLayerActive ? 1 : 0);
    this.map.setLayerZIndex(this.markerLayer, 100);
  }

  setBaseLayer(type: string) {
    localStorage.setItem('baseMap', type);
    this.currentBaseMap = type;
    if (type === 'default') {
      this.map.setBaseLayer(this.defaultMap);
    } else if (type === 'terrain') {
      this.map.setBaseLayer(this.terrainMap);
    } else if (type === 'satellite') {
      this.map.setBaseLayer(this.satelliteMap);
    }
  }

  setMapZIndex() {
    this.map.setLayerZIndex(this.markerLayer, 900);
  }

  unitClicked(unit: any, focus = true) {
    if (this.previousUnit && this.previousUnit.truck_no === unit.truck_no && this.previousUnit.selected === true &&
        focus) {
      Object.keys(this.unitMap).forEach(key => {
        this.unitMap[key].updateShaded(false);
      });
      unit.updateShaded(false);
      this.routingLayer.removeRoute('ptRoute');
      this.unitSelected = false;
      this.defaultMapLocation();
      this.previousUnit = undefined;
      this.markerLayer.clearMarkers();
      Object.keys(this.markerMap).forEach(key => {
        this.markerLayer.addMarker(this.markerMap[key]);
      });
      return;
    } else {
      Object.keys(this.unitMap).forEach(key => {
        this.unitMap[key].updateShaded(true);
      });
      unit.updateSelected(true);
      unit.updateShaded(false);
      if (this.previousUnit && this.previousUnit !== unit) {
        this.previousUnit.updateSelected(false);
      }
    }
    this.previousUnit = unit;
    if (focus) {
      this.unitSelected = true;
      this.routingLayer.removeRoute('ptRoute');
      this.markerLayer.clearMarkers();
      if (unit.pickup_stop && unit.delivery_stop &&
          (unit.pickup_stop.location_city || unit.delivery_stop.location_city) &&
          (unit.delivery_stop.location_city || unit.delivery_stop.location_city) && unit.points) {
        this.createRoute(unit.stops, result => {
          if (this.unitSelected) {
            this.markerLayer.clearMarkers();
            this.markerLayer.addMarker(unit.marker);
            const bounds = new ALKMaps.Bounds();
            result.stops.forEach(stop => {
              bounds.extend(stop);
            });
            if (unit.points) {
              bounds.extend(this.getLonLat(unit.points.longitude, unit.points.latitude));
            }
            const ratio = 366 / window.innerHeight;
            const lonLat = new ALKMaps.LonLat(bounds.left - ((bounds.right - bounds.left) * ratio),
                (bounds.top + bounds.bottom) / 2);
            bounds.extend(lonLat);
            this.map.zoomToExtent(bounds);
          } else {
            this.routingLayer.removeRoute('ptRoute');
          }
        });
      } else {
        let message: string;
        if (unit.points) {
          this.markerLayer.addMarker(unit.marker);
          const bounds = new ALKMaps.Bounds();
          bounds.extend(this.getLonLat(unit.points.longitude, unit.points.latitude));
          const ratio = 366 / window.innerHeight;
          const lonLat = new ALKMaps.LonLat(bounds.left - ((bounds.right - bounds.left) * ratio),
              (bounds.top + bounds.bottom) / 2);
          bounds.extend(lonLat);
          this.map.zoomToExtent(bounds);
          this.map.zoomTo(10);
          if (unit.delivery_stop) {
            message = 'Valid stop locations must be provided.';
          }
        } else {
          this.defaultMapLocation();
          message = 'No location Information.';
        }
        if (message) {
          this.snackBar.open(message, 'Close', {
            panelClass: ['message'],
            duration: 3000,
          });
        }
      }
    }
  }

  clearMarkers() {
    Object.keys(this.markerMap).forEach(key => {
      this.setMarkerVisibility(this.markerMap[key].unit, false);
    });
  }

  addMarkersToMap() {
    this.defaultMapLocation();
    Object.keys(this.markerMap).forEach(key => {
      this.setMarkerVisibility(this.markerMap[key].unit, true);
    });
  }

  getLonLat(longitude, latitude: any): any {
    const source = new ALKMaps.Projection('EPSG:4326');
    const destination = this.map.getProjectionObject();
    const lonLat = new ALKMaps.LonLat(longitude, latitude);
    return lonLat.transform(source, destination);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resizeEvent.bind(this));
  }

  resizeEvent(): any {
      this.gridHeight = window.innerHeight;
  }

  openChanged() {
    if (this.drawerOpen) {
      this.drawerWidth = 23;
    } else if (window.innerWidth < 500) {
      this.drawerWidth = 358;
    } else {
      this.drawerWidth = 389;
    }
    this.drawerOpen = !this.drawerOpen;
  }
}
