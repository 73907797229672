import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const appRoutes: Routes = [
  {
    path: 'map',
    loadChildren: 'app/map/map.module#MapModule'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
        appRoutes,
        {}
    )
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class AppRoutingModule {
}
