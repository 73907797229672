import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'timeFormatter'})
export class TimeFormatterPipe implements PipeTransform {
  transform(time: string): string {
    const split = time.split(':').reverse();
    let result = '';
    if (split.length >= 2) {
      result += (+split[1] / 24) >= 1 ? Math.floor(+split[1] / 24) + 'd ' : '';
      result += (+split[1] % 24) > 0 ? (+split[1] % 24) + 'h ' : '';
    }
    result += split.length >= 1 ? +split[0] + 'm' : '';
    return result;
  }
}
