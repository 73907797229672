import {DOCUMENT} from '@angular/common';
import {
  Component, ChangeDetectorRef, AfterViewInit,
  OnInit, Inject
} from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.html',
  styleUrls: ['./app.css']
})
export class AppComponent implements AfterViewInit, OnInit {
  constructor(
      public changeDetectorRef: ChangeDetectorRef,
      @Inject(DOCUMENT) public document: Document) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }
}
