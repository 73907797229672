export const StopStatus = {
  open: 0,
  enroute: 1,
  late: 2,
  arrived: 3,
  pickedup: 4,
  delivered: 5,
  completed: 6,
  available: 100
};
