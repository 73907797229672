import {NgModule, PLATFORM_ID, NgZone} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {CONFIG} from '@angular/fire/analytics';
import {FormsModule} from '@angular/forms';
import {AngularFirestore} from '@angular/fire/firestore';
import {FlipModule} from 'ngx-flip';
import {MapService} from './map.service';
import {MapComponent} from './map.component';
import {MapDrawerComponent} from './map_drawer.component';
import {ElementsModule} from '../elements/elements.module';
import {TimeFormatterPipe} from './time_formatter.pipe';
import {AngularFirestoreAnalytics, AngularFirestoreDatabase, AngularFireAuthDatabase} from './firebase.factory';
import { MapRoutingModule } from './map-routing.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    MapRoutingModule,
    ElementsModule,
    FlipModule,
    FormsModule,
    DragDropModule,
    MatChipsModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatCheckboxModule
  ],
  declarations: [
    MapComponent,
    MapDrawerComponent,
    TimeFormatterPipe
  ],
  providers: [AngularFirestore, MapService,
    {
      provide: CONFIG, useValue: {
        send_page_view: false,
        allow_ad_personalization_signals: false,
        anonymize_ip: true
      }
    },
    {
      provide: 'firebaseDatabase',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFirestoreDatabase
    },
    {
      provide: 'firebaseDatabaseAuth',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFireAuthDatabase
    },
    {
      provide: 'firebaseAnalytics',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFirestoreAnalytics
    }]
})

export class MapModule {
}
