// The file contents for the current environment will overwrite these during
// build. The build system defaults to the dev environment which uses
// `environment.ts`, but if you do `ng build --env=prod` then
// `environment.prod.ts` will be used instead. The list of which env maps to
// which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  ptServiceUrl: 'https://pt-legacy-service.rtspro.dev/',
  pcMilerAPIKey: '342A219D10D4414E9023E81B2589A754',
  firebase: {
    databaseURL: 'https://protransport-rts-pro-dev.firebaseio.com/',
    apiKey: 'AIzaSyCS3ORgBjandldTBQ0qMymv3UhHEmjG7GE',
    projectId: 'rts-pro-dev',
  },
  firebaseAnalytics: {
    apiKey: 'AIzaSyA54njeRl8HgA_1LQe9QRaCtESlr3DvzEo',
    authDomain: 'carrierpro-dev.firebaseapp.com',
    databaseURL: 'https://carrierpro-dev.firebaseio.com',
    projectId: 'carrierpro-dev',
    storageBucket: 'carrierpro-dev.appspot.com',
    messagingSenderId: '980554620681',
    appId: '1:980554620681:web:3d0bd3ae9a4ed2b8157dd8',
    measurementId: 'G-Y7W83YK56Q',
  },
};
