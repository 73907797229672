import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {map, share, take} from 'rxjs/operators';
import {environment} from '../../environments/environment';

declare let ALKMaps: any;

@Injectable()
export class MapService {
  dbRef: AngularFireList<any[]>;
  unitsObservable: Observable<any>;
  unitObservables: Observable<{}>[] = [];
  map: any;

  constructor(private logger: NGXLogger,
    @Inject('firebaseDatabase') private firebaseDatabase: AngularFireDatabase,
    private http: HttpClient) {
  }

  subsribeToFirestore(context, customerId, clickHandler): Observable<Observable<{}>[]> {
    this.dbRef = this.firebaseDatabase.list(`/live_truck/${customerId}/units`);
    this.unitsObservable = this.dbRef.snapshotChanges().pipe(
        map((changes) => {
          return changes.map(unitSnapshot => {
            return unitSnapshot.payload.val();
          });
        }));
    return this.subscribe(context, customerId, clickHandler);
  }

  subscribe(context, customerId, clickHandler): Observable<Observable<{}>[]> {
    return new Observable<Observable<{}>[]>((observer) => {
      this.unitsObservable.pipe(take(1)).toPromise().then(unitRefs => {
        unitRefs.forEach(unit => {
          unit = unit.info;
          if (unit.is_active) {
            const unitRef = this.firebaseDatabase.list(`/live_truck/${customerId}/units/${unit.truck_id}`)
                .snapshotChanges()
                .pipe(map(change => {
                  return change[0].payload.val();
                }));

            const unitObservable = new Observable(unitObserver => {
              let previousUpdate: any;
              unitRef.subscribe(changedUnit => {
                unit = this.createMarker(context, this.processUnit(changedUnit), clickHandler);
                unit.updateShaded = value => {
                  unit.shaded = value;
                  previousUpdate = unit;
                  unitObserver.next(unit);
                };
                unit.updateSelected = value => {
                  unit.selected = value;
                  previousUpdate = unit;
                  unitObserver.next(unit);
                };
                if (previousUpdate) {
                  unit.shaded = previousUpdate.shaded;
                  unit.selected = previousUpdate.selected;
                }
                previousUpdate = unit;
                unitObserver.next(unit);
              });
            });
            this.unitObservables.push(unitObservable);
          }
        });
        observer.next(this.unitObservables);
      });
    }).pipe(share());
  }

  createMarker(context, unit: any, clickHandler: Function): any {
    let image = './assets/marker_blue.svg';
    if (unit.isLate) {
      image = './assets/marker_red.svg';
    } else if (unit.isOnTime) {
      image = './assets/marker_green.svg';
    }
    if (!unit || !unit.points) {
      return unit;
    }

    const lon = unit.points.longitude;
    const lat = unit.points.latitude;
    const marker = ALKMaps.Marker2.Anchored.topleft(unit['truck_no'],
        new ALKMaps.LonLat(lon, lat).transform(new ALKMaps.Projection('EPSG:4326'), this.map.getProjectionObject()),
        new ALKMaps.Size(31, 42),
        `<img style="width: 31px; height: 52px" src="${image}" />`,
        {
          map: this.map,
          size: new ALKMaps.Size(0, 0),
          offset: new ALKMaps.Pixel(17, 17)
        },
        null,
        null
    );
    marker.setBackgroundColor('transparent');
    marker.defaulClickHandler = () => {
      clickHandler(unit, marker, context);
    };
    unit.marker = marker;
    marker.unit = unit;
    return unit;
  }

  processUnit(unit: any) {
    if (unit.delivery_stop) {
      unit.nextStop = unit.stops.find(stop => stop.id === +unit.next_stop_id);
    }
    if (!unit.nextStop) {
      unit.isAvailable = true;
    } else if (unit.nextStop.status === 2) {
      unit.isLate = true;
    } else {
      unit.isOnTime = true;
    }


    if (unit.points && !unit.nextStop) {
      const inputs = {
        'lonLat': new ALKMaps.LonLat(unit.points.longitude, unit.points.latitude),
        'async': true,
        'success': response => {
          if (response && response[0] && response[0].Address) {
            unit.nearestCity = response[0].Address.City;
            unit.nearestState = response[0].Address.State;
          }
        }
      };
      ALKMaps.Geocoder.reverseGeocode(inputs);
    } else if (unit.points && unit.nextStop) {
      unit.distance = 'Loading';
      const inputs = {
        'lonLat': new ALKMaps.LonLat(unit.points.longitude, unit.points.latitude),
        'async': true,
        'success': response => {
          if (response && response[0] && response[0].Address) {
            const body = {
              provider: 'pcmiler',
              fromCity: response[0].Address.City,
              fromState: response[0].Address.State,
              fromZip: response[0].Address.Zip,
              toCity: unit.nextStop['location_city'],
              toState: unit.nextStop['location_state'],
              toZip: unit.nextStop['location_zip']
            };

            const url = environment.ptServiceUrl + 'miles/getMiles';
            return this.http.post<Response>(url, body, {}).toPromise().then(responseBody => {
              unit.distance = responseBody['miles'];
            }).catch(error => {
              unit.distance = 'Unknown';
            });
          }
        },
        'failure': error => {
          unit.distance = 'Unknown';
        }
      };
      ALKMaps.Geocoder.reverseGeocode(inputs);
    } else {
      unit.distance = 'Unknown';
    }
    return unit;
  }
}
